import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Flex } from "../Grid";

const Bubble = styled(Flex)`
  position: relative;
  border: 1px solid ${props =>
    props.light ? themeGet("colors.EYGrey02.65") : themeGet("colors.EYGrey01")};
  margin: 0;
  padding: 25px;
  width: 100%;
  justify-content: center;

  &:before, &:after {
    content: "";
    position: absolute;
    border-style: solid;
  }

  ${props =>
    props.left &&
    `&:before {
    left: 75px;
    right: auto;
    bottom: -24px;
    border-width: 24px 51px 0 0;
    border-color: ${
      props.light
        ? themeGet("colors.EYGrey02.65")(props)
        : themeGet("colors.EYGrey01")(props)
    } transparent transparent transparent;
  }`}

  ${props =>
    props.left &&
    `&:after {
    left: 76px;
    right: auto;
    bottom: -22px;
    border-width: 23px 50px 0 0;
    border-color: ${
      props.light
        ? themeGet("colors.white.100")(props)
        : themeGet("colors.EYOffBlack.default")(props)
    } transparent transparent transparent;
  }`}

  ${props =>
    !props.left &&
    `&:before {
    left: auto;
    right: 75px;
    bottom: -24px;
    border-width: 0 51px 24px 0;
    border-color: transparent ${
      props.light
        ? themeGet("colors.EYGrey02.65")(props)
        : themeGet("colors.EYGrey01")(props)
    } transparent transparent;
  }`}

  ${props =>
    !props.left &&
    `&:after {
    left: auto;
    right: 76px;
    bottom: -22px;
    border-width: 0 50px 23px 0;
    border-color: transparent ${
      props.light
        ? themeGet("colors.white.100")(props)
        : themeGet("colors.EYOffBlack.default")(props)
    } transparent transparent;
  }`}

  @media screen and (min-width: ${themeGet("breakpoints.lg")}) {
    margin: ${props => (props.left ? "23px 0 0 40px" : "23px 40px 0 0")};
    padding: ${props =>
      props.wide
        ? "64px"
        : props.left
        ? "40px 55px 45px 40px"
        : "40px 40px 45px 55px;"};

    ${props =>
      props.left &&
      `&:before {
      left: -24px;
      right: auto;
      bottom: ${props.wide ? "113px" : "auto"};
      top: ${props.wide ? "auto" : "19px"};
      border-width: 0 24px 51px 0;
      border-color: transparent ${
        props.light
          ? themeGet("colors.EYGrey02.65")(props)
          : themeGet("colors.EYGrey01")(props)
      } transparent transparent;
    }`}

    ${props =>
      props.left &&
      `&:after {
      left: -22px;
      right: auto;
      bottom: ${props.wide ? "113px" : "auto"};
      top: ${props.wide ? "auto" : "20px"};
      border-width: 0 23px 50px 0;
      border-color: transparent ${
        props.light
          ? themeGet("colors.white.100")(props)
          : themeGet("colors.EYOffBlack.default")(props)
      } transparent transparent;
    }`}

    ${props =>
      !props.left &&
      `&:before {
      left: auto;
      right: -24px;
      bottom: ${props.wide ? "113px" : "auto"};
      top: ${props.wide ? "auto" : "19px"};
      border-width: 51px 24px 0 0;
      border-color: ${
        props.light
          ? themeGet("colors.EYGrey02.65")(props)
          : themeGet("colors.EYGrey01")(props)
      } transparent transparent transparent;
    }`}

    ${props =>
      !props.left &&
      `&:after {
      left: auto;
      right: -22px;
      bottom: ${props.wide ? "113px" : "auto"};
      top: ${props.wide ? "auto" : "20px"};
      border-width: 50px 23px 0 0;
      border-color: ${
        props.light
          ? themeGet("colors.white.100")(props)
          : themeGet("colors.EYOffBlack.default")(props)
      } transparent transparent transparent;
    }`}
  }
`;

Bubble.defaultProps = {};

export { Bubble };
