import React from "react";
import MarkdownContent from "../components/MarkdownContent";

import YellowLine from "../components/YellowLine";

import { Flex } from "../style/Grid";
import { Text } from "../style/Text";
import { Image } from "../style/Image";
import { Bubble as BubbleStyle } from "../style/Bubble";
import { Heading } from "../style/Heading";
import Button from "./Button";
import nbsp from "../utils/nbsp";

const Bubble = props => (
  <Flex
    mb={[
      "35px",
      null,
      null,
      props.wide ? "80px" : props.button ? "95px" : "65px",
      null,
    ]}
    width="100%"
    flexDirection={
      props.left
        ? ["column-reverse", null, null, "row", null]
        : ["column-reverse", null, null, "row-reverse", null]
    }
    id={props.id ? props.id : ""}
  >
    <Flex
      flexDirection={
        props.left
          ? ["row", null, null, "column", null]
          : ["row-reverse", null, null, "column", null]
      }
      alignItems={
        props.left
          ? ["flex-start", null, null, "flex-end", null]
          : ["flex-start", null, null, "flex-start", null]
      }
      justifyContent={[
        "flex-start",
        null,
        null,
        props.wide ? "flex-end" : "flex-start",
        null,
      ]}
      flexGrow="1"
      mb={props.wide ? ["0", null, null, "30px", null] : "auto"}
      width={["100%", null, null, "300px", null]}
      css="@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { height: 100%}"
    >
      {props.heading && (
        <YellowLine
          left={props.left}
          rightSpace={0}
          position="relative"
          mb="auto"
          ml={props.left ? "auto" : "100px"}
          mr={props.left ? "100px" : "auto"}
          alignSelf={props.left ? "flex-start" : "flex-end"}
          display={["none", null, null, "flex", null]}
        >
          <Heading
            as="h2"
            color={props.light ? "EYOffBlack.default" : "white.100"}
            fontSize={["4", null, null, "5", null]}
            fontWeight="light"
            lineHeight="2"
            mt="20px"
            css="@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) { width: 271px}"
          >
            {nbsp(props.heading)}
          </Heading>
        </YellowLine>
      )}
      {props.image?.childImageSharp ? (
          <Image
            image={props.image}
            width={["66px", null, null, "88px", null]}
            mr={props.left ? ["8px", null, null, "0", null] : 0}
            ml={props.left ? 0 : ["8px", null, null, "0", null]}
            borderRadius="44px"
            mb="15px"
            mt={props.wide ? ["0", null, null, "50px", null] : "auto"}
            overflow="hidden"
          />
      ) : (
        <Image
            src={props.image}
            width={["66px", null, null, "88px", null]}
            mr={props.left ? ["8px", null, null, "0", null] : 0}
            ml={props.left ? 0 : ["8px", null, null, "0", null]}
            borderRadius="44px"
            mb="15px"
            mt={props.wide ? ["0", null, null, "50px", null] : "auto"}
            overflow="hidden"
          />
      )}
      <Flex
        flexDirection="column"
        textAlign={
          props.left
            ? ["left", null, null, "right", null]
            : ["right", null, null, "left", null]
        }
      >
        <Text
          as="strong"
          color={props.light ? "black.100" : "white.100"}
          fontWeight="bold"
          fontSize="2"
          mb="2px"
        >
          {nbsp(props.name)}
        </Text>
        <Text
          color={props.light ? "black.50" : "white.50"}
          fontSize="1"
          fontWeight="light"
        >
          {nbsp(props.position)}
        </Text>
        {props.department && (
          <Text
            color={props.light ? "black.50" : "white.50"}
            fontSize="1"
            fontWeight="light"
          >
            {nbsp(props.department)}
          </Text>
        )}
      </Flex>
    </Flex>
    <Flex
      width={
        props.wide
          ? ["100%", null, null, null, "846px"]
          : ["100%", null, null, "512px", null]
      }
      mb={["30px", null, null, "0", null]}
    >
      <BubbleStyle
        left={props.left}
        light={props.light}
        wide={props.wide}
        flexDirection="column"
        alignItems="flex-start"
      >
        {!props.renderRawDescription ? (
          <MarkdownContent
            fontSize="2"
            color={props.light ? "EYOffBlack.default" : "white.100"}
            fontWeight="light"
            lineHeight="4"
            content={nbsp(props.description)}
          />
        ) : (
          nbsp(props.description)
        )}

        {props.button && (
          <Button
            href={props.button.href}
            position={["static", null, null, "absolute", null]}
            bottom="-25px"
          >
            {props.button.label}
          </Button>
        )}
      </BubbleStyle>
    </Flex>
    {props.heading && (
      <YellowLine
        left={props.left}
        rightSpace={0}
        position="relative"
        mb="50px"
        maxWidth={["150px", null, null, "250px", null]}
        alignSelf={props.left ? "flex-start" : "flex-end"}
        display={["flex", null, null, "none", null]}
      >
        <Heading
          as="h2"
          color={props.light ? "black.100" : "white.100"}
          fontSize={["4", null, null, "5", null]}
          fontWeight="light"
          lineHeight="2"
        >
          {nbsp(props.heading)}
        </Heading>
      </YellowLine>
    )}
  </Flex>
);

export default Bubble;
