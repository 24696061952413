import React from "react";
import showdown from "showdown";

import { Markdown } from "../../style/Markdown";

const converter = new showdown.Converter();

const MarkdownContent = props => (
  <Markdown
    {...props}
    dangerouslySetInnerHTML={{ __html: converter.makeHtml(props.content) }}
  />
);

export default MarkdownContent;
