import React, { useState, useRef } from "react";
import Swiper from "react-id-swiper";
import { theme } from "../../style/theme";
import "swiper/swiper-bundle.css";
import nbsp from "../../utils/nbsp";
import styled from "styled-components";

import { TopicLinks as TopicLinksStyle } from "../../style/TopicLinks";
import { TopicLinksMobile as TopicLinksMobileStyle } from "../../style/TopicLinksMobile";
import { Heading } from "../../style/Heading";
import { TopicTile } from "../../style/TopicTile";
import { Image } from "../../style/Image";
import { Text } from "../../style/Text";
import { Flex } from "../../style/Grid";

import arrowLight from "../../images/arrow-light.svg";

const Content = styled.div`
  position: relative;
  z-index: 1;
`;

const SlideImageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

const TopicLinks = (props) => {
  const swiperRef = useRef(null);
  const { items } = props;

  let timeout;
  const [activeItem, setActiveItem] = useState(0);
  const swiperParams = {
    slidesPerView: "auto",
    simulateTouch: true,
    loop: true,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: false,
    },
  };

  return (
    <>
      <TopicLinksStyle
        {...props}
        display={["none", null, null, "flex", null]}
        height="641px"
        position="relative"
        overflow="hidden"
        alignItems="stretch"
        justifyContent="flex-start"
        backgroundColor="EYOffBlack.default"
      >
        {props.children && (
          <Heading
            as="h2"
            color="white.100"
            fontSize={["4", null, "6", null, null]}
            fontWeight="light"
            position="absolute"
            left="50px"
            top="50px"
            zIndex="30"
          >
            {nbsp(props.children)}
          </Heading>
        )}

        {items.map((item) => (
          <Flex
            backgroundSize="cover"
            position="absolute"
            left="0"
            top="0"
            bottom="0"
            right="0"
            alt="test"
            opacity={item.heading === items[activeItem].heading ? 1 : 0}
            css={{
              transition: "opacity 500ms",
            }}
          >
            {item?.image?.childImageSharp && (
              <Image
                image={item.image}
                cover
                autoHeight
                objectPosition="50% top"
              />
            )}
          </Flex>
        ))}

        {items.map((item, index) => (
          <TopicTile
            to={item.link}
            width={`calc(${100 / items.length}%)`}
            mr={index !== items.length - 1 ? "1px" : "0px"}
            p={["20px", null, null, null, items.length > 4 ? "20px" : "50px"]}
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            backgroundColor="black.40"
            onMouseEnter={() => {
              timeout = setTimeout(function () {
                setActiveItem(index);
              }, 150);
            }}
            onMouseLeave={() => {
              clearTimeout(timeout);
            }}
          >
            <Heading
              as="h3"
              fontWeight="light"
              color="white.100"
              fontSize={[
                "3",
                null,
                null,
                null,
                props.headingSize ? props.headingSize : "5",
              ]}
              lineHeight="2"
              mb="15px"
              maxWidth="100%"
            >
              {nbsp(item.heading)}
            </Heading>
            <Text
              lineHeight="1"
              color="white.70"
              fontWeight="light"
              fontSize={["1", null, null, null, "2"]}
              maxWidth="100%"
              maxHeight={
                item.heading === items[activeItem].heading
                  ? ["300px", null, null, null, "200px"]
                  : "0"
              }
            >
              {nbsp(item.text)}
            </Text>
            <Flex alignItems="center" mt="15px" position="relative" zIndex="21">
              <Text
                color="white.100"
                fontWeight={["light", null, null, null, "bold"]}
                fontSize={["1", null, null, null, items.length > 4 ? 1 : 2]}
                maxWidth={
                  item.heading === items[activeItem].heading ? "150px" : "0"
                }
              >
                Chci vědět víc
              </Text>
              <Image
                src={arrowLight}
                height={["18", null, null, null, "22"]}
                ml={
                  item.heading === items[activeItem].heading
                    ? items.length > 4
                      ? "10px"
                      : "20px"
                    : "0"
                }
              />
            </Flex>
            <Flex
              as="span"
              position="absolute"
              top="0"
              left="0"
              width="100%"
              height="100%"
              opacity={item.heading === items[activeItem].heading ? "0.6" : "0"}
              backgroundImage="linear-gradient(180deg, transparent 0%, rgba(26, 26, 36, 0) 50%, #000000 100%)"
              z-index="20"
              css={{
                transition: `opacity ${theme.times.transition}`,
              }}
            />
          </TopicTile>
        ))}
      </TopicLinksStyle>

      <Flex flexDirection="column" display={["flex", null, null, "none", null]}>
        {props.children && (
          <Heading
            as="h2"
            color="EYOffBlack.default"
            fontSize="4"
            fontWeight="light"
            mb="20px"
            mt="40px"
            mx="30px"
          >
            {nbsp(props.children)}
          </Heading>
        )}
        <TopicLinksMobileStyle
          minHeight="410px"
          mobileBackgroundTopCenter={true}
        >
          <Swiper {...swiperParams} ref={swiperRef}>
            {items.map((item, i) => (
              <TopicTile
                to={item.link}
                flexShrink="0"
                mr={"1px"}
                p={["20px", null, null, null, "50px"]}
                flexDirection="column"
                justifyContent="flex-end"
                alignItems="flex-start"
                backgroundColor="black.60"
              >
                <Content>
                  <Heading
                    as="h3"
                    fontWeight="light"
                    color="white.100"
                    fontSize={[
                      "3",
                      null,
                      null,
                      null,
                      props.headingSize ? props.headingSize : "5",
                    ]}
                    lineHeight="2"
                    mb="20px"
                  >
                    {nbsp(item.heading)}
                  </Heading>
                  <Image
                    src={arrowLight}
                    height={["18", null, null, null, "22"]}
                  />
                </Content>
                {items[i]?.image?.childImageSharp && (
                  <SlideImageWrapper>
                    <Image image={items[i].image} cover />
                  </SlideImageWrapper>
                )}
              </TopicTile>
            ))}
          </Swiper>
        </TopicLinksMobileStyle>
      </Flex>
    </>
  );
};

export default TopicLinks;
