import styled from "styled-components";
import { space, color, layout, typography } from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

const Markdown = styled.div`
  width: 100%;

  p {
    margin-bottom: 10px;
  }
  
  ul {
    list-style: none inside;
    margin: 0;
    
    li {
      position: relative;
      padding-left: 20px;
    
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 11px;
        display: block;
        width: 8px;
        height: 8px;
        background-color: ${themeGet("colors.EYYellow.default")};
      }
    }
  }
  
  ${space}
  ${color}
  ${layout}
  ${typography}
`;

Markdown.displayName = `Markdown`;

Markdown.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
};

export { Markdown };
