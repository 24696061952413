import styled from "styled-components";

import { Flex } from "../Grid";

const TopicLinks = styled(Flex)`
  & > div:nth-of-type(1) {
    position: absolute !important;
    width: 100%;
    height: 100%;

    left: 0;
    top: 0;
    z-index: 10;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  & > img {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

export { TopicLinks };
