import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";

import { Flex } from "../Grid";

const TopicLinksMobile = styled(Flex)`
  position: relative;
  margin-bottom: 30px;

  .swiper-container {
    width: 100%;
    background-size: cover;
    background: url(${(props) => props.img}) no-repeat center;

    ${(props) =>
      props.mobileBackgroundTopCenter &&
      `
      @media screen and (max-width: 576px) {
        background-position: top center;
      }
      `}
  }

  .swiper-wrapper {
  }

  .swiper-slide {
    width: 274px;
    &::after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      background-color: #000000;
      opacity: 0.3;
    }
  }

  .swiper-pagination {
    padding-left: 4%;
    text-align: left;
    bottom: 0 !important;
    height: 30px;
    background-color: ${themeGet("colors.white.100")};
    opacity: 0;

    span {
      margin-top: 13px !important;
    }

    .swiper-pagination-bullet {
      width: 8px;
      height: 8px;
      border: 1px solid #c4c4cd;
      border-radius: 0;
      transform: none;
      background: transparent;
      opacity: 1;
    }

    .swiper-pagination-bullet-active {
      background: ${themeGet("colors.EYOffBlack.default")};
      border: 0;
    }
  }
`;

export { TopicLinksMobile };
