import styled from "styled-components";

import { Box } from "../Grid";

const TriangleSharp = styled(Box)`
  transform: skewY(-4.2deg);
  transform-origin: top left;

  @media screen and (min-width: 1920px) {
    height: 10vw;
    bottom: -10vw;
    transform: skewY(-3.2deg);
  }
`;

TriangleSharp.defaultProps = {
  as: "span",
  position: "absolute",
  left: "0",
  width: "100%",
  height: "140px",
  bottom: "-140px",
  backgroundColor: "EYOffBlack.default",
  zIndex: 2,
};

export default TriangleSharp;
