import React from "react";

import { Flex, Box } from "../../style/Grid";

const YellowLine = props => {
  const lineHeight = 16;
  const lineHeightMobile = 8;
  const lineSize = [
    `${lineHeightMobile}px`,
    null,
    null,
    `${lineHeight}px`,
    null,
  ];
  const space = props.space ? props.space : 0;

  const dots = [];
  for (let i = 1; i < 4; i++) {
    const pos = [
      `-${space + i * lineHeightMobile * 2}px`,
      null,
      null,
      `-${space + i * lineHeight * 2}px`,
      null,
    ];

    dots.push(
      <Box
        key={i}
        position="absolute"
        height={lineSize}
        width={lineSize}
        bg="EYYellow.default"
        left={!props.left ? pos : "auto"}
        right={props.left ? pos : "auto"}
        bottom={["-20px", null, null, "-30px", null]}
      />
    );
  }

  return (
    <Flex {...props}>
      {props.children}
      <Box
        position="absolute"
        height={lineSize}
        width="9999px"
        bg="EYYellow.default"
        left={!props.left ? `-${space}px` : "auto"}
        right={props.left ? `-${space}px` : "auto"}
        bottom={["-20px", null, null, "-30px", null]}
      />
      {dots}
    </Flex>
  );
};

export default YellowLine;
