import styled from "styled-components";
import {
  space,
  color,
  layout,
  typography,
  flexbox,
  background,
} from "styled-system";
import propTypes from "@styled-system/prop-types";
import { themeGet } from "@styled-system/theme-get";

import { Link as GatsbyLink } from "gatsby";

const TopicTile = styled(GatsbyLink)`
  display: flex;
  position: relative;
  text-decoration: none;
  z-index: 11;
  
  ${space}
  ${flexbox}
  ${color}
  ${layout}
  ${typography}
  ${background}
  
  & > h3 {
    position: relative;
    z-index: 21;
    overflow-wrap: normal;
    
    @media screen and (max-width: 1600px) {
      font-size: ${themeGet("fontSizes.3")}
    }
  }
  
  & > p {
    position: relative;
    overflow: hidden;
    transition: max-height 400ms ease-in-out;
    z-index: 21;
  }
  
  & > div {   
    & > p {
      white-space: nowrap;
      overflow: hidden;
      transition: max-width ${themeGet("times.transition")};
    }
  }
  
  
`;

TopicTile.displayName = `TopicTile`;

TopicTile.defaultProps = {};

TopicTile.propTypes = {
  ...propTypes.space,
  ...propTypes.color,
  ...propTypes.layout,
  ...propTypes.typography,
  ...propTypes.flexbox,
  ...propTypes.background,
};

export { TopicTile };
